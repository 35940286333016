/* playfair-display-regular - latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
    url('/_assets/fonts/playfair-display-v22-latin-regular.woff2')
      format('woff2'),
    url('/_assets/fonts/playfair-display-v22-latin-regular.woff') format('woff');
}
/* playfair-display-700 - latin 
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('../fonts/playfair-display-v22-latin-700.woff2') format('woff2'),
       url('../fonts/playfair-display-v22-latin-700.woff') format('woff');
}*/

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(''),
    url('/_assets/fonts/poppins-v15-latin-regular.woff2') format('woff2'),
    url('/_assets/fonts/poppins-v15-latin-regular.woff') format('woff');
}

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local(''),
    url('/_assets/fonts/poppins-v15-latin-600.woff2') format('woff2'),
    url('/_assets/fonts/poppins-v15-latin-600.woff') format('woff');
}

/* poppins-700 - latin */
/* @font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(''),
       url('/_assets/fonts/poppins-v15-latin-700.woff2') format('woff2'),
       url('/_assets/fonts/poppins-v15-latin-700.woff') format('woff');
}*/
