html {
  color: #111;
  line-height: 1.4;
  font-family: var(--font-serif);
  min-height: 100%;
  background: var(--bg-gradient);

  font-size: 0.9em;
  @media (min-width: 760px) {
    font-size: 1.15em;
  }
}

body.main {
  padding: 5vh 5vw 5vh 5vw;
  min-height: 100%;
  width: 90vw;
  position: relative;
  background-color: #fff;

  opacity: 1;
  transition: opacity 400ms;
  &.fade-out {
    opacity: 0;
  }
}

.responsive-wrap {
  max-width: 1915px;
  margin: 0 auto;
}

::selection {
  color: #000;
  -webkit-text-fill-color: #000;
  background: rgba(122, 179, 226, 0.99); /* well… it works */
}

/* @keyframes fade {
  0% {
    background-position: 53% 0%;
  }
  50% {
    background-position: 48% 100%;
  }
  100% {
    background-position: 53% 0%;
  }
} */

body.main {
  a {
    color: #111;
    text-decoration: none;
    border-bottom: 2px solid #111;
    transition: border-bottom-color 0.2s;
  }

  a:hover {
    border-bottom-color: var(--brand);
  }

  a.switched {
    border-bottom-color: #fff;
  }

  a.no-under {
    border: 0;
  }

  a.switched:hover {
    border: 0;
    text-decoration: none;
    border-bottom: 2px solid #111;
  }

  ul {
    margin-top: 1.5em;
    padding-left: 1.5em;
    list-style-type: '▬▬ ';

    li {
      margin-top: 0.4em;
    }
    li::marker {
      color: var(--brand);
    }
  }

  .sans {
    font-family: var(--font-sans);
    font-size: 1rem;
  }

  .serif {
    font-family: var(--font-serif);
    font-size: 1.16rem;
  }

  @mixin bigFont {
    font-family: var(--font-sans);
    font-weight: bold;
    color: var(--brand);
    font-size: var(--xl-font);
    line-height: 1.1em;
    padding-bottom: 0.5rem;

    background: var(--bg-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 760px) {
      overflow-wrap: break-word;
      /*hyphens: auto;
    add when supported
    -ms-hyphenate-limit-chars: 10 3 4;
    hyphenate-limit-chars: 10 3 4;*/
    }
  }

  h1,
  .big-font {
    @include bigFont;
  }

  h3 {
    font-family: var(--font-sans);
    font-weight: bold;
    font-size: 2.8rem;
    line-height: 1.1;

    //color: var(--brand);
    // background: var(--bg-gradient);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  blockquote {
    margin: 0;

    footer {
      margin-left: 0.5rem;
    }

    footer,
    cite {
      font-family: var(--font-sans);
      font-style: normal;
      font-weight: normal;
      line-height: 1.8;
      letter-spacing: 0.02em;
    }
  }

  // #page-verlag {
  //   .img-wrapper {
  //     float: right;
  //   }
  // }
}
